<template>
  <div class="analysis-tab">
    <section class="section is-small">
      <h1 class="title is-medium is-marginless">
        Analysis
      </h1>
      <h1 class="subtitle is-marginless">
        Compare the results of several games
      </h1>
      <div class="section mt-2">
        <b-notification
          :active="showError"
          @close="closeError"
          type="is-danger"
          >{{ showError ? error.message : "" }}</b-notification
        >
        <div class="box">
          <select-game-type
            v-model="chooseType"
            :multi-select="false"
            :show-plan="false"
            :show-article="false"
          ></select-game-type>
          <b-field
            grouped
            custom-class="is-size-5"
            label="Select finished games :"
          >
            <div class="field is-expanded">
              <b-taginput
                :maxtags="maxNb"
                ref="taginput"
                ellipsis
                :allow-new="false"
                v-model="selectedGames"
                :open-on-focus="true"
                field="name"
                group-field="groupName"
                icon="plus"
                autocomplete
                placeholder="Find game"
                :data="filterStatus"
                @typing="
                  text => {
                    searchText = text;
                  }
                "
                @input="searchText = null"
              >
              </b-taginput>
            </div>
            <div class="field has-addons">
              <b-button
                rounded
                type="is-info"
                size="is-small"
                @click="selectAll"
                :disabled="
                  !filterStatus.length || selectedGames.length >= maxNb
                "
                >Select all</b-button
              >
              <b-button
                rounded
                class="ml-2"
                type="is-warning"
                size="is-small"
                @click="clearSelection"
                :disabled="!selectedGames.length"
                >Clear</b-button
              >
            </div>
          </b-field>
        </div>
        <div
          class="has-text-grey has-text-centered is-size-5"
          v-if="!selectedGames.length"
        >
          <b-icon icon="poll"></b-icon>
          No game selected
        </div>
        <analysis-lean
          v-if="selectedGames.length && chooseType[0] === 'leangame'"
          :selected-games="selectedGames"
        ></analysis-lean>
        <analysis-bg
          v-if="selectedGames.length && chooseType[0] === 'beergame'"
          :selected-games="selectedGames"
        ></analysis-bg>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectGameType from "@/components/Dashboard/SelectGameType.vue";
import AnalysisBg from "@/components/bg/Stats/AnalysisBg.vue";
import AnalysisLean from "@/components/lean/Stats/AnalysisLean.vue";
import { sortGames } from "@/store/helperFunctions.js";

export default {
  name: "analysisTab",
  components: {
    SelectGameType,
    AnalysisBg,
    AnalysisLean
  },
  computed: {
    ...mapGetters(["error", "showError", "concatGamesList"]),
    filterStatus() {
      return [...this.concatGamesList]
        .filter(game => {
          let conditStatus =
            !game.archived && this.chooseType[0] === game.type && game.finished;
          let alreadySelec = this.selectedGames.some(selecGame => {
            return game.id === selecGame.id;
          });
          let conditTyping =
            !this.searchText ||
            this.searchText.length === 0 ||
            game.name
              .toString()
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) >= 0;
          return conditStatus && !alreadySelec && conditTyping;
        })
        .map(game => {
          let groupName =
            game.creatorName === "Me" ? "My Games :" : `${game.creatorName} :`;
          return { ...game, groupName: groupName };
        })
        .sort((a, b) => {
          return a.creatorName === "Me" && b.creatorName !== "Me"
            ? -1
            : sortGames(a, b);
        });
    }
  },
  watch: {
    chooseType() {
      this.clearSelection();
    }
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    clearSelection() {
      this.selectedGames = [];
    },
    selectAll() {
      let maxadd = Math.min(
        this.maxNb - this.selectedGames.length,
        this.filterStatus.length
      );
      if (maxadd > 0) {
        this.selectedGames.push(...this.filterStatus.slice(0, maxadd));
      }
    }
  },
  data() {
    return {
      maxNb: 50,
      chooseType: ["beergame"],
      searchText: null,
      selectedGames: []
    };
  }
};
</script>
<style lang="scss">
.analysis-tab {
  .bg-has-border-bottom {
    border-bottom: solid 2px rgba(#000, 0.1);
  }
  .bg-has-border-left {
    border-left: solid 2px rgba(#000, 0.1);
  }
}
</style>
