import { HorizontalBar } from "vue-chartjs";
import tinycolor from "tinycolor2";
// const { reactiveProp } = mixins;

export default {
  name: "compareChart",
  extends: HorizontalBar,
  //   mixins: [reactiveProp],
  props: {
    metric: String,
    stats: Object,
    detailed: Boolean
  },
  methods: {
    launchUpdate() {
      this.renderChart(this.chartData, this.options);
    }
  },
  computed: {
    chartData() {
      if (!this.metric || !this.stats) {
        return {};
      }
      let sortTab = Object.values(this.stats.gamesObj).sort((a, b) => {
        if (this.stats.metrics[this.metric].sort === "asc") {
          return a[this.metric].all - b[this.metric].all;
        } else {
          return b[this.metric].all - a[this.metric].all;
        }
      });
      let plusTab = tinycolor(this.stats.metrics[this.metric].color)
        .monochromatic(sortTab.length * 2)
        .sort((a, b) => {
          return b.getLuminance() - a.getLuminance();
        });
      let negTab = this.stats.metrics[this.metric].colorNeg
        ? tinycolor(this.stats.metrics[this.metric].colorNeg)
            .monochromatic(sortTab.length * 2)
            .sort((a, b) => {
              return b.getLuminance() - a.getLuminance();
            })
        : plusTab;
      let dataTab = sortTab.map(gg => gg[this.metric].all);
      let colTab = dataTab.map((val, ind) => {
        let t = val > 0 ? plusTab[ind] : negTab[ind];
        return t.setAlpha(0.5 + (5 - ind) / 5).toRgbString();
      });
      let gameLabels = sortTab.map((gg, ind) => {
        let med = ind === 0 ? "🥇" : ind === 1 ? "🥈" : ind === 2 ? "🥉" : "";
        return med.concat(gg.name);
      });
      if (!this.detailed) {
        return {
          labels: gameLabels,
          datasets: [
            {
              label: "Total Supply-Chain",
              backgroundColor: colTab,
              data: dataTab
            }
          ]
        };
      } else {
        let allFaciNames = [];
        sortTab.forEach(gg => {
          Object.keys(gg[this.metric]).forEach(faciName => {
            if (faciName !== "all") {
              allFaciNames.push(faciName);
            }
          });
        });
        allFaciNames = Object.keys(
          allFaciNames.reduce((p, c) => ((p[c] = true), p), {})
        );
        let faciColTab = tinycolor(this.stats.metrics[this.metric].color)
          .monochromatic(Math.round(allFaciNames.length * 1.8))
          .sort((a, b) => {
            return b.getLuminance() - a.getLuminance();
          })
          .map(t => {
            return t.toRgbString();
          });
        let dataSetArr = [];
        allFaciNames.forEach((faciName, faciInd) => {
          let arrFaciData = [];
          sortTab.forEach(gg => {
            if (gg[this.metric] && gg[this.metric][faciName]) {
              arrFaciData.push(gg[this.metric][faciName]);
            } else {
              arrFaciData.push(0);
            }
          });
          dataSetArr.push({
            label: this.$options.filters.capitalize(faciName),
            backgroundColor: faciColTab[faciInd],
            data: arrFaciData
          });
        });
        return {
          labels: gameLabels,
          datasets: dataSetArr
        };
      }
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: true },
        scales: {
          xAxes: [
            {
              ticks: {
                suggestedMin: 0,
                suggestedMax: 0,
                callback: value => {
                  return this.stats.metrics[this.metric].currency
                    ? this.$options.filters.currency(
                        value,
                        this.stats.metrics[this.metric].currency
                      )
                    : value;
                }
              }
            }
          ]
        }
      };
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
