<template>
  <div class="templates-tab">
    <section class="section">
      <div class="container">
        <div class="columns mb-0">
          <div class="column">
            <h1 class="title is-marginless">
              Templates
            </h1>
            <h1 class="subtitle is-marginless">
              Set of parameters to help you create games faster
            </h1>
          </div>
          <div class="column is-narrow has-text-centered">
            <b-button
              size="is-medium"
              type="is-info"
              rounded
              class="px-5"
              icon-left="plus"
              @click="showCreateTemplateModal = true"
              >Create New Template</b-button
            >
          </div>
        </div>
        <div class="section" size="is-medium">
          <b-tabs size="is-medium" class="is-multiline" v-model="activeTab">
            <b-tab-item
              :label="tempTab.label"
              :disabled="tempTab.disab"
              v-for="tempTab in templateTabs"
              :key="tempTab.label"
            >
              <div
                class="has-text-centered has-text-grey is-size-5 pb-5"
                v-if="!tempTab.myTypes || !tempTab.myTypes.length"
              >
                No template yet in this list
              </div>
              <div
                v-for="gt in tempTab.myTypes"
                :key="`gtlist-${tempTab.label}-${gt.type}`"
              >
                <b-collapse
                  :aria-id="`collapsetype-${gt.type}`"
                  animation="slide"
                  :open="true"
                >
                  <div
                    class="media mb-1"
                    slot="trigger"
                    slot-scope="props"
                    :aria-controls="`collapsetype-${gt.type}`"
                  >
                    <figure class="media-left mr-1">
                      <p class="image is-32x32">
                        <img :src="gameTypes[gt.type].logo" />
                      </p>
                    </figure>
                    <p class="media-content ml-3 title is-5 pt-1">
                      {{ gt.name }}
                      <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                      </b-icon>
                    </p>
                  </div>

                  <div class="columns is-multiline mt-3">
                    <div
                      v-for="temp in tempTab.list[gt.type]"
                      :key="temp.id"
                      class="column is-3"
                    >
                      <div class="box p-4">
                        <header class="buttons is-right mb-0">
                          <b-button
                            @click="openTemplate(temp, tempTab.canEdit)"
                            :icon-left="tempTab.canEdit ? 'pencil' : 'eye'"
                            type="is-info is-light"
                            size="is-small"
                            rounded
                            >{{ tempTab.canEdit ? "Edit" : "View" }}</b-button
                          >
                          <b-button
                            v-if="tempTab.canEdit"
                            @click="deleteTemplate(temp)"
                            icon-left="delete"
                            type="is-danger is-light"
                            size="is-small"
                            rounded
                            >Delete</b-button
                          >
                        </header>
                        <p class="mb-2">
                          <b-icon
                            class="media-left"
                            type="is-primary"
                            size="is-medium"
                            :icon="temp.icon"
                          ></b-icon>
                        </p>
                        <h1 class="title is-5">
                          {{ temp.title }}
                        </h1>
                        <p class="subtitle">
                          {{ temp.description }}
                        </p>
                        <p
                          v-if="tempTab.label === 'Team Templates'"
                          class="has-text-info is-italic is-size-6 has-text-right"
                        >
                          Created by :
                          {{
                            teamAccount.members &&
                            teamAccount.members[temp.creator]
                              ? teamAccount.members[temp.creator].name
                              : "teammate"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-collapse>
                <div class="is-divider my-5"></div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </section>
    <b-modal :active.sync="isTemplateModalActive" has-modal-card>
      <edit-template
        :temp="showTemplate"
        v-if="showTemplate"
        :can-edit="canEdit"
      ></edit-template>
    </b-modal>
    <!-- CREATE NEW TEMPLATE MODAL : -->
    <b-modal :active.sync="showCreateTemplateModal" has-modal-card>
      <div class="bg-create-template-wrapper modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered">
            Create Template : {{ newTemplate.title }}
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="showCreateTemplateModal = false"
          ></button>
        </header>
        <section class="modal-card-body py-2">
          <form ref="newtempform">
            <div class="box">
              <select-game-type
                v-model="chooseType"
                :show-plan="true"
                :show-article="false"
              ></select-game-type>
              <b-field>
                <template slot="label">
                  Start from :
                  <em class="has-text-grey has-text-weight-normal"
                    >You'll be able to customize the parameters on the next
                    window</em
                  >
                </template>
                <dropdown-select-template
                  v-model="selectTemplate"
                  :type="chooseType[0]"
                  @input="chooseStartFromTemplate"
                ></dropdown-select-template>
              </b-field>
            </div>
            <div class="box">
              <b-field label="New Template Name* :">
                <b-input
                  v-model="newTemplate.title"
                  type="text"
                  expanded
                  required
                  @keyup.enter.native="createNewTemplate"
                ></b-input>
              </b-field>
              <b-field class="mt-4">
                <template slot="label">
                  New Template Logo :
                  <b-icon
                    size="is-small"
                    class="has-text-grey"
                    :icon="newTemplate.icon"
                  ></b-icon>
                </template>
                <div class="block">
                  <b-radio
                    v-for="ico in iconsList"
                    :key="ico"
                    v-model="newTemplate.icon"
                    :native-value="ico"
                  >
                    <b-icon :icon="ico"></b-icon>
                  </b-radio>
                </div>
              </b-field>
              <b-field class="mt-4" label="New Template Description :">
                <b-input
                  v-model="newTemplate.description"
                  type="textarea"
                  expanded
                  rows="1"
                  @keyup.enter.native="createNewTemplate"
                ></b-input>
              </b-field>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot buttons is-centered">
          <b-button
            rounded
            type="is-grey"
            size="is-medium"
            @click="showCreateTemplateModal = false"
            >Cancel</b-button
          >
          <b-button
            rounded
            type="is-success"
            size="is-medium"
            @click="createNewTemplate"
            >Create and Configure</b-button
          >
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditTemplate from "@/components/Dashboard/EditTemplate.vue";
import DropdownSelectTemplate from "@/components/Dashboard/DropdownSelectTemplate.vue";
import SelectGameType from "@/components/Dashboard/SelectGameType.vue";

export default {
  name: "templatesTab",
  components: { EditTemplate, DropdownSelectTemplate, SelectGameType },
  mounted() {
    setTimeout(() => {
      if (this.userTemplates.length) {
        this.activeTab = 1;
      }
    }, 50);
  },
  methods: {
    openTemplate(temp, canEdit) {
      this.isTemplateModalActive = true;
      this.canEdit = canEdit;
      this.showTemplate = { ...temp };
      this.showCreateTemplateModal = false;
    },
    deleteTemplate(temp) {
      this.$buefy.dialog.confirm({
        message: `The template <b>${temp.title}</b> will be permanently deleted. Be careful, this can't be undone!`,
        size: "is-medium",
        confirmText: "Delete",
        type: "is-danger",
        onConfirm: () => {
          this.$store.dispatch("deleteTemplate", temp);
        }
      });
    },
    chooseStartFromTemplate(temp) {
      this.newTemplate.config = { ...temp.config };
    },
    createNewTemplate() {
      let isValid = this.$refs.newtempform.checkValidity();
      if (isValid) {
        this.$store.dispatch("createTemplate", this.newTemplate).then(temp => {
          this.openTemplate(temp, true);
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      "gameTypes",
      "allTemplates",
      "defaultTemplates",
      "userTemplates",
      "teamAccount",
      "teamTemplates"
    ]),
    templateTabs() {
      let defList = {};
      let myList = {};
      let teamList = {};
      Object.values(this.gameTypes).forEach(gt => {
        defList[gt.type] = this.defaultTemplates.filter(
          tt => tt.type === gt.type
        );
        myList[gt.type] = [...this.userTemplates]
          .sort((a, b) => {
            return a.created_at && b.created_at
              ? a.created_at.seconds - b.created_at.seconds
              : 0;
          })
          .filter(tt => tt.type === gt.type);
        teamList[gt.type] = [...this.teamTemplates]
          .sort((a, b) => {
            return a.created_at && b.created_at
              ? a.created_at.seconds - b.created_at.seconds
              : 0;
          })
          .filter(tt => tt.type === gt.type);
      });
      return [
        {
          label: "Default Templates",
          list: defList,
          myTypes: Object.values(this.gameTypes).filter(
            gt => defList[gt.type] && defList[gt.type].length
          ),
          disab: false,
          canEdit: false
        },
        {
          label: "My Templates",
          list: myList,
          myTypes: Object.values(this.gameTypes).filter(
            gt => myList[gt.type] && myList[gt.type].length
          ),
          disab: false,
          canEdit: true
        },
        {
          label: "Team Templates",
          list: teamList,
          myTypes: Object.values(this.gameTypes).filter(
            gt => teamList[gt.type] && teamList[gt.type].length
          ),
          disab: !this.teamAccount,
          canEdit: false
        }
      ];
    }
  },
  watch: {
    chooseType(newVal) {
      if (this.selectTemplate.type !== newVal[0]) {
        this.newTemplate.type = newVal[0];
        this.selectTemplate = {
          ...this.allTemplates.find(temp => {
            return temp.type === newVal[0];
          })
        };
        this.chooseStartFromTemplate(this.selectTemplate);
      }
    }
  },
  data() {
    return {
      chooseType: ["beergame"],
      canEdit: false,
      activeTab: 0,
      selectTemplate: null,
      newTemplate: {
        title: "",
        type: "beergame",
        icon: "truck",
        description: "",
        config: null
      },
      iconsList: [
        "cart",
        "call-split",
        "airplane",
        "domain",
        "home",
        "city",
        "briefcase",
        "web",
        "ferry",
        "truck",
        "telescope",
        "headphones",
        "rocket",
        "factory",
        "glass-mug",
        "train",
        "pill",
        "account-box",
        "laptop",
        "mouse",
        "copyright",
        "animation",
        "cog",
        "cellphone",
        "car",
        "dots-hexagon",
        "radiobox-marked"
      ],
      isTemplateModalActive: false,
      showTemplate: null,
      showCreateTemplateModal: false
    };
  }
};
</script>
<style lang="scss">
.templates-tab {
  .is-divider {
    opacity: 50%;
  }
}
</style>
