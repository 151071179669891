<template>
  <div class="edit-template-wrapper modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered">
        {{ temp.title }} Template
      </p>
      <button
        class="delete"
        aria-label="close"
        @click="$parent.close()"
      ></button>
    </header>
    <section class="modal-card-body py-2">
      <p class="has-text-centered mb-3 has-text-weight-bold" v-if="!canEdit">
        This template can't be modified
      </p>
      <form v-if="newTemplateData" id="update-temp-form" ref="tempform">
        <div class="box" v-if="canEdit">
          <div>
            <b-button
              class="is-pulled-right has-text-weight-bold"
              :type="!recapDef ? 'is-success is-light' : 'is-warning'"
              :icon-left="!recapDef ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="recapDef = !recapDef"
              >{{ !recapDef ? "OK" : "Edit" }}</b-button
            >
          </div>
          <div
            class="bg-transit-slide"
            :class="{ 'bg-transit-slide-open': recapDef }"
          >
            <div class="columns">
              <p class="column pb-0 is-narrow">
                Logo :
                <b
                  ><b-icon size="is-small" :icon="newTemplateData.icon"></b-icon
                ></b>
              </p>
              <p class="column pb-0 is-narrow">
                Name : <b>{{ newTemplateData.title }}</b>
              </p>
            </div>
            <p>
              Description :
              <b>{{ newTemplateData.description }}</b>
            </p>
          </div>
          <div
            class="bg-transit-slide"
            :class="{ 'bg-transit-slide-open': !recapDef }"
          >
            <b-field label="Template Name :">
              <b-input
                v-model="newTemplateData.title"
                type="text"
                expanded
                required
                @keyup.enter.native="updateAllTemp"
              ></b-input>
            </b-field>
            <b-field class="mt-4" label="Template Logo :">
              <div class="block">
                <b-radio
                  v-for="ico in iconsList"
                  :key="ico"
                  v-model="newTemplateData.icon"
                  :native-value="ico"
                >
                  <b-icon :icon="ico"></b-icon>
                </b-radio>
              </div>
            </b-field>
            <b-field class="mt-4" label="Template Description :">
              <b-input
                v-model="newTemplateData.description"
                type="textarea"
                expanded
                rows="1"
                @keyup.enter.native="updateAllTemp"
              ></b-input>
            </b-field>
          </div>
        </div>
        <bg-config
          v-if="newTemplateData.type === 'beergame'"
          v-model="newTemplateData.config"
          ref="bgconfig"
          :can-edit="canEdit"
        >
        </bg-config>
        <lean-config
          v-if="newTemplateData.type === 'leangame'"
          v-model="newTemplateData.config"
          ref="leanconfig"
          :can-edit="canEdit"
        >
        </lean-config>
      </form>
    </section>
    <footer class="modal-card-foot buttons is-centered">
      <b-button
        rounded
        type="is-grey"
        size="is-medium"
        v-if="isChanged"
        @click="cancelChange"
        >Cancel</b-button
      >
      <b-button
        rounded
        form="update-game-form"
        type="is-success"
        tag="input"
        native-type="submit"
        size="is-medium"
        value="Update template"
        v-if="isChanged"
        @click.prevent="updateAllTemp"
      ></b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BgConfig from "@/components/bg/Create/BgConfig.vue";
import LeanConfig from "@/components/lean/Create/LeanConfig.vue";

export default {
  name: "EditTemplate",
  components: {
    BgConfig,
    LeanConfig
  },
  computed: {
    ...mapGetters(["error", "showError"])
  },
  props: {
    temp: Object,
    canEdit: Boolean
  },
  watch: {
    temp: {
      handler() {
        this.newTemplateData = JSON.parse(JSON.stringify(this.temp));
        this.isChanged = false;
      },
      deep: true
    },
    newTemplateData: {
      handler(newVal, oldVal) {
        if (newVal && oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.temp)) {
            this.isChanged = true;
          } else {
            this.isChanged = false;
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.newTemplateData = JSON.parse(JSON.stringify(this.temp));
  },
  methods: {
    cancelChange() {
      this.newTemplateData = JSON.parse(JSON.stringify(this.temp));
      this.isChanged = false;
    },
    updateAllTemp() {
      let isValid = this.$refs.tempform.checkValidity();
      if (isValid && this.isChanged && this.newTemplateData.id) {
        this.$store
          .dispatch("updateTemplate", {
            tid: this.newTemplateData.id,
            obj: this.newTemplateData
          })
          .then(() => {
            this.isChanged = false;
            this.$buefy.toast.open("Template updated");
          });
      } else if (this.isChanged && this.newTemplateData.id) {
        this.$buefy.toast.open({
          message: "Please correct the errors in the values entered",
          type: "is-danger"
        });
      }
    }
  },
  data() {
    return {
      recapDef: true,
      isChanged: false,
      newTemplateData: null,
      iconsList: [
        "cart",
        "call-split",
        "airplane",
        "domain",
        "home",
        "city",
        "briefcase",
        "web",
        "ferry",
        "truck",
        "telescope",
        "headphones",
        "rocket",
        "factory",
        "glass-mug",
        "train",
        "pill",
        "account-box",
        "laptop",
        "mouse",
        "copyright",
        "animation",
        "cog",
        "cellphone",
        "car",
        "dots-hexagon",
        "radiobox-marked"
      ]
    };
  }
};
</script>

<style lang="scss">
.edit-template-wrapper {
  .title.is-6 {
    margin-bottom: 0.5em;
  }
  .settings-bloc {
    margin-top: 0.5em;
    margin-bottom: 1em;
    padding: 1em 1.5em;
    .b-tabs.block {
      .tab-content {
        padding-top: 0;
        .lane-user-status {
          margin: 0 -1.25em;
        }
        .columns,
        .column {
          margin-bottom: 0;
        }
        .column {
          padding: left 3em;
          padding-right: 3em;
        }
      }
    }
  }
}
</style>
