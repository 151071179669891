<template>
  <div class="analysis-bg">
    <div class="box" v-if="selectedGames.length">
      <h1 class="title is-5 mb-2">
        Compare {{ selectedGames.length }} beer game{{
          selectedGames.length > 1 ? "s" : ""
        }}
        :
      </h1>
      <b-field grouped group-multiline position="is-centered" class="mb-5">
        <b-radio-button
          v-model="compareMetric"
          native-value="cost"
          type="is-danger"
        >
          Final Cost
        </b-radio-button>
        <b-radio-button
          v-if="showRevenue"
          v-model="compareMetric"
          native-value="revenue"
          type="is-info is-light"
        >
          Final Revenue
        </b-radio-button>
        <b-radio-button
          v-if="showRevenue"
          v-model="compareMetric"
          native-value="balance"
          type="is-success is-light"
        >
          Final Profit
          <b-tooltip type="is-dark" label="= Revenue - Cost" position="is-top">
            <b-icon
              size="is-small"
              class="ml-1"
              icon="help-circle-outline"
            ></b-icon>
          </b-tooltip>
        </b-radio-button>
        <b-radio-button
          v-model="compareMetric"
          native-value="stockAverage"
          type="is-link"
        >
          Stock Average
        </b-radio-button>

        <b-radio-button
          v-model="compareMetric"
          native-value="fillRate"
          type="is-warning"
        >
          Fill Rate
          <b-tooltip
            type="is-dark"
            label="Fill Rate (%) = Units shipped on time / Total Demand"
            size="is-small"
            position="is-top"
            multilined
          >
            <b-icon
              size="is-small"
              class="ml-1"
              icon="help-circle-outline"
            ></b-icon>
          </b-tooltip>
        </b-radio-button>

        <b-radio-button
          v-model="compareMetric"
          native-value="ordersAverage"
          type="is-success"
        >
          Orders Average
        </b-radio-button>

        <b-radio-button
          v-model="compareMetric"
          native-value="ordersVar"
          type="is-success"
        >
          Orders Variability
          <b-tooltip
            type="is-dark"
            label="Coefficient of Variation (%) = Standard Deviation of Orders / Average Orders"
            size="is-medium"
            position="is-top"
            multilined
          >
            <b-icon
              size="is-small"
              class="ml-1"
              icon="help-circle-outline"
            ></b-icon>
          </b-tooltip>
        </b-radio-button>
      </b-field>
      <div class="columns" v-if="globalStats.metrics[compareMetric]">
        <div class="column">
          <compare-bar-chart
            ref="comparechartglobal"
            :stats="globalStats"
            :metric="compareMetric"
            :detailed="false"
          ></compare-bar-chart>
        </div>
        <div
          class="column"
          v-if="!globalStats.metrics[compareMetric].onlyGlobal"
        >
          <compare-bar-chart
            ref="comparechartfaci"
            :stats="globalStats"
            :metric="compareMetric"
            :detailed="true"
          ></compare-bar-chart>
        </div>
      </div>
    </div>
    <div class="box" v-if="selectedGames.length">
      <h1 class="title is-5 is-marginless">Detailed Charts :</h1>
      <b-field grouped group-multiline position="is-centered" class="mb-3">
        <b-radio-button
          v-model="chartType"
          native-value="total"
          type="is-success"
        >
          Game evolution
        </b-radio-button>
        <b-radio-button
          v-model="chartType"
          native-value="facility"
          type="is-warning"
        >
          Facility evolution
        </b-radio-button>
        <b-radio-button
          v-model="chartType"
          native-value="orders-sellout"
          type="is-success"
        >
          Orders vs. End Consum. Demand
        </b-radio-button>
        <b-radio-button
          v-model="chartType"
          native-value="stock-sellout"
          type="is-link"
        >
          Stock vs. End Consum. Demand
        </b-radio-button>
      </b-field>
      <div
        class="box my-4"
        v-if="chartType === 'total' || chartType === 'facility'"
      >
        <select-metrics
          v-model="checkMetrics"
          :start-with="basicMetrics"
        ></select-metrics>
      </div>
      <div class="pl-4 mt-5" v-if="selectedGames.length > 1">
        <b-checkbox v-model="harmonizeYAxis">
          Harmonize y-axis scales to compare games
        </b-checkbox>
      </div>
      <div class="columns is-multiline mt-1">
        <div
          class="column"
          :class="{
            'is-12': chartType === 'facility',
            'is-6': chartType !== 'facility',
            'bg-has-border-bottom':
              selectedGames.length > 1 && ind < selectedGames.length - 1
          }"
          v-for="(game, ind) in selectedGames"
          v-bind:key="game.id"
        >
          <router-link :to="`/game/${game.id}`" target="_blank">
            <h1 class="title is-5 has-text-centered has-text-primary mb-2">
              {{ game.name | capitalize({ onlyFirstLetter: true }) }}
              <b-icon icon="open-in-new" size="is-small"></b-icon>
            </h1>
          </router-link>
          <stats-section
            v-if="chartType === 'total' || chartType === 'facility'"
            ref="statssection"
            :show-details="false"
            :show-legend="true"
            :show-faci-tab="Object.keys(game.facilities)"
            :show-total="chartType === 'total'"
            :custom-metrics="checkMetrics"
            :game="game"
            :my-style="{ 'max-height': '300px' }"
            :global-max="
              harmonizeYAxis
                ? chartType === 'total'
                  ? globalMaxAll
                  : globalMax
                : { maxPcs: 0, maxVal: 0 }
            "
          ></stats-section>
          <div v-if="chartType === 'orders-sellout'">
            <h1 class="title is-6 has-text-centered">
              Orders vs. End Consumer Demand
            </h1>
            <lines-chart
              :timeUnit="game.params.timeUnit || 'week'"
              baseColor="#06BD98"
              :faciData="globalStats.gamesObj[game.id].ordersObj"
              :focusData="{
                color: '#FFBABB',
                name: 'End Consumer Demand',
                arr: globalStats.gamesObj[game.id].sellOutTab
              }"
              :global-max="
                harmonizeYAxis ? globalMaxOrders : { maxPcs: 0, minPcs: 0 }
              "
            ></lines-chart>
          </div>
          <div v-if="chartType === 'stock-sellout'">
            <h1 class="title is-6 has-text-centered">
              Stock vs. End Consumer Demand
            </h1>
            <lines-chart
              :timeUnit="game.params.timeUnit || 'week'"
              baseColor="#06BD98"
              :faciData="globalStats.gamesObj[game.id].stockObj"
              :focusData="{
                color: '#FFBABB',
                name: 'End Consumer Demand',
                arr: globalStats.gamesObj[game.id].sellOutTab
              }"
              :global-max="
                harmonizeYAxis ? globalMaxStock : { maxPcs: 0, minPcs: 0 }
              "
            ></lines-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsSection from "@/components/bg/Stats/StatsSection.vue";
import CompareBarChart from "@/components/bg/Stats/CompareBarChart.js";
import SelectMetrics from "@/components/bg/Stats/SelectMetrics.vue";
import LinesChart from "@/components/bg/Stats/LinesChartContainer.vue";

let getMean = function(data) {
  if (!data || !data.length) {
    return 0;
  }
  return (
    Math.round(
      (10 *
        data.reduce(function(a, b) {
          return Number(a) + Number(b);
        })) /
        data.length
    ) / 10
  );
};
let getVar = function(data) {
  if (!data || !data.length) {
    return 0;
  }
  let m = getMean(data);
  return Math.round(
    (100 *
      Math.sqrt(
        data.reduce(function(sq, n) {
          return sq + Math.pow(n - m, 2);
        }, 0) /
          (data.length - 1)
      )) /
      m
  );
};
let getFillRate = function(backlogArr, missedArr, demandArr) {
  if (!backlogArr || !backlogArr.length || !demandArr || !demandArr.length) {
    return 0;
  }
  let missedSales = backlogArr.reduce((missed, current, index, array) => {
    return (missed || 0) + Math.max(0, current - array[index - 1]);
  });
  if (missedArr && missedArr.length) {
    missedSales += missedArr.reduce((a, b) => a + b, 0);
  }
  let sumDemand = demandArr.reduce(
    (previous, current) => (current += previous)
  );
  return Math.floor(1000 * (1 - missedSales / sumDemand)) / 10;
};

export default {
  name: "analysisBg",
  components: {
    StatsSection,
    CompareBarChart,
    SelectMetrics,
    LinesChart
  },
  props: {
    selectedGames: Array
  },
  computed: {
    basicMetrics() {
      let retTab = ["stock", "backlog", "order", "cost"];
      if (this.showRevenue) {
        retTab.push("revenue");
      }
      if (this.showMissed) {
        retTab.push("missed");
      }
      return retTab;
    }
  },
  mounted() {
    if (this.selectedGames && this.selectedGames.length) {
      if (
        this.selectedGames[0].revenue ||
        this.selectedGames[0].facilities["1"].revenue
      ) {
        this.compareMetric = "balance";
      }
      this.promiseUpdateStats();
    }
  },
  watch: {
    selectedGames() {
      this.chartType = "total";
      setTimeout(() => {
        this.promiseUpdateStats();
      }, 10);
    },
    compareMetric() {
      setTimeout(() => {
        this.launchCompareChartsUpdate();
      }, 50);
    }
  },
  methods: {
    launchCompareChartsUpdate() {
      if (this.$refs.comparechartglobal) {
        this.$refs.comparechartglobal.launchUpdate();
      }
      if (this.$refs.comparechartfaci) {
        this.$refs.comparechartfaci.launchUpdate();
      }
    },
    promiseUpdateStats() {
      this.$store.commit("setLoading", true);
      if (this.timeoutUpdates) {
        clearTimeout(this.timeoutUpdates);
      }
      this.tryUpdates += 1;
      this.timeoutUpdates = setTimeout(() => {
        let isUpdated = true;
        this.$refs.statssection.forEach(vuecomp => {
          if (
            !vuecomp.statsObj ||
            !vuecomp.game ||
            !vuecomp.statsObj.all ||
            !vuecomp.statsObj.all.week ||
            (vuecomp.statsObj.all.week.length < 1 && vuecomp.game.week > 0)
          ) {
            isUpdated = false;
          }
        });
        if (isUpdated) {
          this.tryUpdates = 0;
          this.$store.commit("setLoading", false);
          setTimeout(() => {
            this.updateStats();
          }, 10);
        } else if (this.tryUpdates > 3000) {
          this.tryUpdates = 0;
          this.$store.commit("setLoading", false);
          this.$store.commit("setError", {
            message: "Update failed - Timeout exceeded"
          });
        } else {
          this.promiseUpdateStats();
        }
      }, 10);
    },
    updateStats() {
      this.showRevenue = false;
      this.showMissed = false;
      this.globalStats = {
        gamesObj: {},
        metrics: {
          cost: {
            color: "#FF3860",
            sort: "asc",
            currency:
              this.$refs.statssection && this.$refs.statssection[0]
                ? this.$refs.statssection[0].game.params.currency
                : "$"
          },
          revenue: {
            color: "#A4ADB2",
            sort: "desc",
            onlyGlobal: true,
            currency:
              this.$refs.statssection && this.$refs.statssection[0]
                ? this.$refs.statssection[0].game.params.currency
                : "$"
          },
          balance: {
            color: "#2ec4b6",
            colorNeg: "#FF3860",
            onlyGlobal: true,
            sort: "desc",
            currency:
              this.$refs.statssection && this.$refs.statssection[0]
                ? this.$refs.statssection[0].game.params.currency
                : "$"
          },
          fillRate: {
            color: "#FFDD57",
            sort: "desc",
            currency: "%"
          },
          ordersAverage: {
            color: "#2ec4b6",
            sort: "asc"
          },
          ordersVar: {
            color: "#2ec4b6",
            sort: "asc",
            currency: "%"
          },
          stockAverage: {
            color: "#209CEE",
            sort: "asc"
          }
        }
      };
      this.globalMaxStock.maxPcs = 0;
      this.globalMaxStock.minPcs = 0;
      this.globalMaxOrders.maxPcs = 0;
      this.globalMaxOrders.minPcs = 0;
      this.globalMax.maxPcs = 0;
      this.globalMax.maxVal = 0;
      this.globalMaxAll.maxPcs = 0;
      this.globalMaxAll.maxVal = 0;
      this.$refs.statssection.forEach(vuecomp => {
        let statsObj = vuecomp.statsObj;
        if (statsObj.maxAllPcs > this.globalMax.maxPcs) {
          this.globalMaxAll.maxPcs = statsObj.maxAllPcs;
        }
        if (statsObj.maxAllVal > this.globalMax.maxVal) {
          this.globalMaxAll.maxVal = statsObj.maxAllVal;
        }
        if (statsObj.maxPcs > this.globalMax.maxPcs) {
          this.globalMax.maxPcs = statsObj.maxPcs;
        }
        if (statsObj.maxVal > this.globalMax.maxVal) {
          this.globalMax.maxVal = statsObj.maxVal;
        }
        let gameObj = {
          name: vuecomp.game.name,
          cost: {
            all: statsObj["all"].cost
              ? statsObj["all"].cost[statsObj["all"].cost.length - 1]
              : 0
          },
          revenue: {
            all: statsObj["all"].revenue
              ? statsObj["all"].revenue[statsObj["all"].revenue.length - 1]
              : 0
          },
          balance: {
            all:
              statsObj["all"].revenue && statsObj["all"].cost
                ? statsObj["all"].revenue[statsObj["all"].revenue.length - 1] -
                  statsObj["all"].cost[statsObj["all"].cost.length - 1]
                : 0
          },
          stockAverage: { all: getMean(statsObj["all"].stock) },
          fillRate: {
            all: getFillRate(
              statsObj["1"]["backlog"],
              statsObj["1"]["missed"],
              statsObj["1"]["demand"]
            )
          },
          ordersAverage: {},
          ordersVar: {},
          ordersObj: {},
          stockObj: {},
          sellOutTab: statsObj["0"] ? statsObj["0"]["order"] : []
        };
        // Check if need to show revenue/missed sales metrics :
        if (getMean(statsObj["all"]["missed"]) > 0) {
          this.showMissed = true;
        }
        if (
          statsObj["all"].revenue &&
          statsObj["all"].revenue[statsObj["all"].revenue.length - 1] > 0
        ) {
          this.showRevenue = true;
        }
        let gameOrdersConcat = [];
        Object.keys(statsObj).forEach(faciId => {
          if (
            faciId !== "all" &&
            faciId !== "0" &&
            typeof statsObj[faciId] === "object"
          ) {
            gameOrdersConcat.push(...statsObj[faciId].order);
            gameObj.cost[vuecomp.game.facilities[faciId].name] =
              statsObj[faciId].cost[statsObj[faciId].cost.length - 1];
            gameObj.revenue[vuecomp.game.facilities[faciId].name] =
              statsObj[faciId].revenue[statsObj[faciId].revenue.length - 1];
            gameObj.balance[vuecomp.game.facilities[faciId].name] =
              statsObj[faciId].revenue[statsObj[faciId].revenue.length - 1] -
              statsObj[faciId].cost[statsObj[faciId].cost.length - 1];
            gameObj.stockAverage[
              vuecomp.game.facilities[faciId].name
            ] = getMean(statsObj[faciId].stock);
            gameObj.ordersAverage[
              vuecomp.game.facilities[faciId].name
            ] = getMean(statsObj[faciId].order);
            gameObj.ordersVar[vuecomp.game.facilities[faciId].name] = getVar(
              statsObj[faciId].order
            );
            gameObj.fillRate[
              vuecomp.game.facilities[faciId].name
            ] = getFillRate(
              statsObj[faciId].backlog,
              statsObj[faciId].missed,
              statsObj[faciId].demand
            );
            gameObj.ordersObj[vuecomp.game.facilities[faciId].name] =
              statsObj[faciId].order;
            gameObj.stockObj[vuecomp.game.facilities[faciId].name] = statsObj[
              faciId
            ].stock.map((val, ii) => {
              return val - statsObj[faciId]["backlog"][ii];
            });
            if (
              Math.max(...statsObj[faciId].order) > this.globalMaxOrders.maxPcs
            ) {
              this.globalMaxOrders.maxPcs = Math.max(...statsObj[faciId].order);
            }
            if (
              Math.max(...statsObj[faciId].stock) > this.globalMaxStock.maxPcs
            ) {
              this.globalMaxStock.maxPcs = Math.max(...statsObj[faciId].stock);
            }
            if (
              Math.max(...statsObj[faciId].backlog) > this.globalMaxStock.minPcs
            ) {
              this.globalMaxStock.minPcs = Math.max(
                ...statsObj[faciId].backlog
              );
            }
          }
        });
        gameObj.ordersAverage["all"] = getMean(gameOrdersConcat);
        gameObj.ordersVar["all"] = getVar(gameOrdersConcat);
        this.globalStats.gamesObj[vuecomp.game.id] = gameObj;
      });
      setTimeout(() => {
        this.launchCompareChartsUpdate();
      }, 50);
    }
  },
  data() {
    return {
      harmonizeYAxis: true,
      showRevenue: false,
      showMissed: false,
      globalMax: { maxPcs: 0, maxVal: 0 },
      globalMaxAll: { maxPcs: 0, maxVal: 0 },
      globalMaxStock: { maxPcs: 0, minPcs: 0 },
      globalMaxOrders: { maxPcs: 0, minPcs: 0 },
      compareMetric: "cost",
      checkMetrics: [],
      chartType: "total",
      globalStats: { metrics: {} },
      tryUpdates: 0,
      timeoutUpdates: null
    };
  }
};
</script>
<style lang="scss">
.analysis-tab {
  .bg-has-border-bottom {
    border-bottom: solid 2px rgba(#000, 0.1);
  }
  .bg-has-border-left {
    border-left: solid 2px rgba(#000, 0.1);
  }
}
</style>
