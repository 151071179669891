<template>
  <div class="analysis-lean">
    <div class="box" v-if="selectedGames.length">
      <h1 class="title is-5 mb-2">
        Compare {{ selectedGames.length }} lean game{{
          selectedGames.length > 1 ? "s" : ""
        }}
        :
      </h1>
      <b-field grouped group-multiline position="is-centered" class="mb-5">
        <b-radio-button
          v-model="compareMetric"
          native-value="profit"
          type="is-success"
          size="is-small"
        >
          Net Profit/Loss
        </b-radio-button>
        <b-radio-button
          v-model="compareMetric"
          native-value="otif"
          type="is-warning"
          size="is-small"
        >
          %OTIF sales
          <b-tooltip
            type="is-dark"
            label="Units shipped on time and conform / Total Demand"
            size="is-small"
            position="is-top"
            multilined
          >
            <b-icon
              size="is-small"
              class="ml-1"
              icon="help-circle-outline"
            ></b-icon>
          </b-tooltip>
        </b-radio-button>
        <b-radio-button
          v-model="compareMetric"
          native-value="stock"
          type="is-info"
          size="is-small"
        >
          Average Inventory
        </b-radio-button>
        <b-radio-button
          v-model="compareMetric"
          native-value="sales"
          type="is-warning"
          size="is-small"
        >
          Units sold
        </b-radio-button>
        <b-radio-button
          v-model="compareMetric"
          native-value="ontimesales"
          type="is-warning"
          size="is-small"
        >
          Units sold on time
        </b-radio-button>
        <b-radio-button
          v-model="compareMetric"
          native-value="defects"
          type="is-info is-light"
          size="is-small"
        >
          Process Defects
          <b-tooltip
            type="is-dark"
            label="Number of failed operations during the process"
            size="is-small"
            position="is-top"
            multilined
          >
            <b-icon
              size="is-small"
              class="ml-1"
              icon="help-circle-outline"
            ></b-icon>
          </b-tooltip>
        </b-radio-button>
        <b-radio-button
          v-model="compareMetric"
          native-value="defdelivery"
          type="is-info is-light"
          size="is-small"
        >
          Delivered Defects
          <b-tooltip
            type="is-dark"
            label="Number of units delivered to the customer with a defect"
            size="is-small"
            position="is-top"
            multilined
          >
            <b-icon
              size="is-small"
              class="ml-1"
              icon="help-circle-outline"
            ></b-icon>
          </b-tooltip>
        </b-radio-button>
      </b-field>
      <div
        class="columns"
        :key="`comp-${updateStatsKey}`"
        v-if="globalStats.metrics[compareMetric]"
      >
        <div class="column">
          <compare-bar-chart
            ref="comparechartglobal"
            :stats="globalStats"
            :metric="compareMetric"
            :detailed="false"
          ></compare-bar-chart>
        </div>
        <div
          class="column"
          v-if="!globalStats.metrics[compareMetric].onlyGlobal"
        >
          <compare-bar-chart
            ref="comparechartfaci"
            :stats="globalStats"
            :metric="compareMetric"
            :detailed="true"
            v-if="globalStats.metrics[compareMetric]"
          ></compare-bar-chart>
        </div>
      </div>
    </div>
    <div class="box" v-if="selectedGames.length">
      <h1 class="title is-5 mb-3">Compare side-by-side :</h1>
      <b-field grouped group-multiline position="is-centered" class="mb-3">
        <b-radio-button
          v-model="chartType"
          native-value="pnl"
          type="is-success"
        >
          Profit and Loss
        </b-radio-button>
        <b-radio-button
          v-model="chartType"
          native-value="time"
          type="is-success"
        >
          Cycle Times
        </b-radio-button>
      </b-field>
      <div class="columns is-multiline" :key="`pnl-${updateStatsKey}`">
        <div
          class="column is-half"
          v-for="statObj in Object.values(allGamesStats)"
          :key="`pnl-${statObj.gameObj.id}`"
        >
          <router-link
            :to="`/game/${statObj.gameObj.id}/results`"
            target="_blank"
          >
            <h1 class="title is-5 has-text-centered has-text-primary mb-2">
              {{ statObj.gameObj.name | capitalize({ onlyFirstLetter: true }) }}
              <b-icon icon="open-in-new" class="ml-2" size="is-small"></b-icon>
            </h1>
          </router-link>
          <lean-recap-table
            class="is-size-6"
            v-if="chartType === 'pnl' && statObj.recapStatsTable"
            :is-recap="true"
            :recap-obj="statObj.recapStatsTable"
            :game="statObj.gameObj"
          ></lean-recap-table>
          <stacked-lean-bar-chart
            ref="leantimechart"
            v-if="chartType === 'time' && statObj.productsStatsObj"
            :stats="statObj.productsStatsObj"
            :game="statObj.gameObj"
            :metrics="['workdeskTime', 'changeoverTime']"
            :takt="
              statObj.calcTaktTime.totalDem ? statObj.calcTaktTime.takt : null
            "
          ></stacked-lean-bar-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeanRecapTable from "@/components/lean/Stats/LeanRecapTable.vue";
import StackedLeanBarChart from "@/components/lean/Stats/StackedLeanBarChart.js";
import CompareBarChart from "@/components/bg/Stats/CompareBarChart.js";
import calculateLeanStats from "@/components/lean/Stats/calculateLeanStats.js";

export default {
  name: "analysisBg",
  components: {
    LeanRecapTable,
    StackedLeanBarChart,
    CompareBarChart
  },
  props: {
    selectedGames: Array
  },
  computed: {
    ...mapGetters(["concatGamesList"])
  },
  mounted() {
    this.initStatsData();
  },
  watch: {
    selectedGames() {
      this.chartType = "pnl";
      setTimeout(() => {
        this.initStatsData();
      }, 10);
    },
    compareMetric() {
      setTimeout(() => {
        this.updateStatsDisplay();
      }, 10);
    }
  },
  methods: {
    initStatsData() {
      let updatePromises = [];
      if (!this.selectedGames || !this.selectedGames.length) {
        return;
      }
      this.$store.commit("setLoading", true);
      Object.keys(this.allGamesStats).forEach(statGid => {
        let foundSelec = this.selectedGames.some(gg => gg.id === statGid);
        if (!foundSelec) {
          delete this.allGamesStats[statGid];
        }
      });
      this.selectedGames.forEach(gg => {
        if (!this.allGamesStats[gg.id]) {
          updatePromises.push(this.$store.dispatch("getProductsOnce", gg));
        }
      });

      Promise.all(updatePromises).then(resArr => {
        resArr.forEach(resObj => {
          this.allGamesStats[resObj.game.id] = calculateLeanStats(
            resObj.game,
            resObj.products
          );
        });
        this.$store.commit("setLoading", false);
        this.updateStatsDisplay();
      });
    },
    updateStatsDisplay() {
      let curr = this.selectedGames.length
        ? this.selectedGames[0].params.currency
        : "$";
      this.globalStats = {
        gamesObj: {},
        metrics: {
          profit: {
            color: "#2ec4b6",
            colorNeg: "#eb5757",
            sort: "desc",
            onlyGlobal: true,
            currency: curr
          },
          stock: {
            color: "#0086e3",
            sort: "asc"
          },
          sales: {
            color: "#FFDD57",
            sort: "desc",
            onlyGlobal: true
          },
          ontimesales: {
            color: "#FFDD57",
            sort: "desc",
            onlyGlobal: true
          },
          otif: {
            color: "#FFDD57",
            sort: "desc",
            onlyGlobal: true,
            currency: "%"
          },
          defects: {
            color: "#A4ADB2",
            sort: "asc"
          },
          defdelivery: {
            color: "#A4ADB2",
            onlyGlobal: true,
            sort: "asc"
          }
        }
      };
      Object.values(this.allGamesStats).forEach(statObj => {
        this.globalStats.gamesObj[statObj.gameObj.id] = {
          name: statObj.gameObj.name,
          profit: {
            all: statObj.recapStatsTable.totalProfit
          },
          stock: {
            all: statObj.stockEvolution.nbSec
              ? Math.round(
                  (10 *
                    (statObj.stockEvolution.totalSec.all.faciTot["all"] || 0)) /
                    statObj.stockEvolution.nbSec
                ) / 10
              : 0
          },
          sales: {
            all: statObj.recapStatsTable.totalSales
          },
          ontimesales: {
            all: statObj.recapStatsTable.totalSalesOnTime
          },
          otif: {
            all: statObj.calcTaktTime.totalDem
              ? Math.round(
                  (100 * statObj.recapStatsTable.totalOTIF) /
                    statObj.calcTaktTime.totalDem
                )
              : 0
          },
          defects: {
            all: statObj.faciOpeStatus.tots["Failed"]
          },
          defdelivery: {
            all: statObj.custoDefects.all.nbProd
          }
        };
        //Populate all faci to sum and add compare data
        Object.keys(statObj.gameObj.facilities).forEach(fid => {
          //FACI STOCK
          let faciAvgStock = statObj.stockEvolution.nbSec
            ? Math.round(
                (10 * (statObj.stockEvolution.totalSec.all.faciTot[fid] || 0)) /
                  statObj.stockEvolution.nbSec
              ) / 10
            : 0;
          this.globalStats.gamesObj[statObj.gameObj.id].stock[
            statObj.gameObj.facilities[fid].name
          ] = faciAvgStock;
          this.globalStats.gamesObj[statObj.gameObj.id].defects[
            statObj.gameObj.facilities[fid].name
          ] =
            statObj.productsStatsObj["all"] &&
            statObj.productsStatsObj["all"].faciOpeData[fid] &&
            statObj.productsStatsObj["all"].faciOpeData[fid].countOpes["Failed"]
              ? statObj.productsStatsObj["all"].faciOpeData[fid].countOpes[
                  "Failed"
                ].nb
              : 0;
        });
      });
      setTimeout(() => {
        this.updateStatsKey += 1;
      }, 1);
    }
  },
  data() {
    return {
      updateStatsKey: 0,
      allGamesStats: {},
      compareMetric: "profit",
      checkMetrics: [],
      chartType: "pnl",
      globalStats: { metrics: {} },
      tryUpdates: 0,
      timeoutUpdates: null
    };
  }
};
</script>
<style lang="scss">
.analysis-tab {
  .bg-has-border-bottom {
    border-bottom: solid 2px rgba(#000, 0.1);
  }
  .bg-has-border-left {
    border-left: solid 2px rgba(#000, 0.1);
  }
}
</style>
